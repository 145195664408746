


























import { InputSetups } from '@/mixins/input-setups'
import { BoardService } from '@/includes/services/BoardService'
import { BoardAdmin, BoardAdminPermissions } from '@/includes/types/Board.types'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Emit, Mixins, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component
export default class AdminByName extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {

  @Prop() boardId!: string

  @Prop({ type: Object }) permissions!:BoardAdminPermissions | null

  @Prop({ type:Array, default: () => null }) channelIds!:Array<number> | null

  userName = ''

  isLoading = false

  @Emit('updatedAdminList')
  updatedAdminList(admins:Array<BoardAdmin>):Array<BoardAdmin> {
    return admins
  }

  addAdminUsername(): void {
    this.isLoading = true

    BoardService.addBoardAdminByName('tg', {
      board_key: this.boardId,
      username: this.userName,
      permissions:this.permissions,
      channel_ids: this.channelIds,
    })
      .then(res => {
        this.userName = ''
        this.updatedAdminList(res.admins)
        successNotification()
      })
      .catch(errorNotification)
      .finally(() => {
        this.isLoading = false
      })
  }

}
